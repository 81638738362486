import React from 'react';
import {
  CommissionCards,
  CommissionRateTable,
  GenericTable,
} from '../../../../components';
import {Grid} from '@mui/material';
import {images} from '../../../../common';
import {tableHeading} from './table';
import {TotalSalesRecord} from '../../../../components/total-commission';
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

export function AdminBussinessAmbassador({data}) {
  const {
    deductibles,
    subscriptions,
    customers,
    registrations,
    salesDistribution,
    commissionTable,
    totalCommission,
    totalMonthlySale,
    totalCommissionRate,
  } = data || {};

  const topCardsList = [
    {
      icon: images.deductiblesIcon,
      tile: 'Total Sales',
      count: (
        <>
          <span
            style={{cursor: 'pointer'}}
            data-tooltip-id={'sales'}
            data-tooltip-content={'Sales based on 1st month'}>
            {`$${deductibles}` || 'N/A'}
          </span>
          <Tooltip id={'sales'} classNameArrow="arrow" />
        </>
      ),
    },
    {
      icon: images.subscriptionsIcon,
      tile: 'Total Subscriptions',
      count: `${subscriptions}` || 'N/A',
    },
    {
      icon: images.customersIcon,
      tile: 'Customers',
      count: `${customers}` || 'N/A',
    },
    {
      icon: images.registerationIcon,
      tile: 'Registrations',
      count: `${registrations}` || 'N/A',
    },
  ];

  return (
    <div>
      <div className="cards-container-calculator">
        <CommissionCards list={topCardsList} />
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
          <div className="commission-distribution-container">
            <div className="title">Sales Commission Distribution</div>
            <GenericTable headings={tableHeading()} data={salesDistribution} />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <CommissionRateTable dataList={commissionTable} />
          <TotalSalesRecord
            totalMonthlySale={`$${totalMonthlySale}`}
            commissionRate={`$${totalCommissionRate}%`}
            totalSale={`${totalCommission}$`}
          />
        </Grid>
      </Grid>
    </div>
  );
}
