import React, {useState, useEffect} from 'react';
import './styles.scss';
import {ALL_TEXT, ENUMS} from '../../../common';
import {
  GenericModal,
  GenericTable,
  CustomizedSearch,
  GenericButton,
  AddNewUser,
  CellValue,
} from '../../../components';
import {TableHeading, staffLinkpopupTypes} from './table.js';
import Pagination from '@mui/material/Pagination';
import {getValuesFromEnumsObj} from '../../../utils/helpers';
import {useDispatch, useSelector} from 'react-redux';
import {
  setDeleteModal,
  setEditModal,
  setIsCommissionPopUp,
  setIsLinkGeneratorPopup,
  setSelectedEmployeeData,
  setIsAccountVerificationPopUp,
  setIsRequestDocumentPopup,
  setIsDownloadSuccessPopup,
} from '../../../redux/slice/administrator';
import {
  usePostApiAdminGetAllEmployeesMutation,
  NodukAdmnApi,
} from '../../../redux/slice/nodukAdmin.ts';
import {openModal, setModalDetails} from '../../../redux/slice/popup-modal';
import {toast} from 'react-toastify';
import {getProfile, getToken} from '../../../utils/localstorage';
import {AddEdiCommission} from '../commission-calculator/agency(agent)/add-commission';
import AccountVerificationstatus from './AccountVerificationStatus';
import RequestDocuments from './RequestDocuments';
import DownloadFilesPopup from './downloadfilespopup.js';
import {useLocation} from 'react-router-dom';

function StaffManagment() {
  const [addNewUser, setAddNewUser] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  // const [userRole, setUserRole] = useState(-1);
  const [popuoLink, setPopuoLink] = useState('');
  const isLoggedIn = getToken() !== '';

  const [selectedStaff, setSelectedStaff] = useState({
    role: 0,
    status: 0,
  });

  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const emailFromURL = urlSearchParams.get('email');

  var employeesList = [];
  let totalPages = 1;
  const {
    useLazyGetApiAdminActivateDeactivateEmployeeQuery,
    useLazyGetApiAdminGetEmpForgetLinkQuery,
    useLazyGetApiAdminGetEmpActivationLinkQuery,
  } = NodukAdmnApi;
  const [
    getAllEmployees,
    {isLoading, isError, isSuccess, data: EmployeesData, error},
  ] = usePostApiAdminGetAllEmployeesMutation();
  const [forgotPasswordAPI, forgotPasswordResponse] =
    useLazyGetApiAdminGetEmpForgetLinkQuery();
  const [activationLinkAPI, activationLinkResponse] =
    useLazyGetApiAdminGetEmpActivationLinkQuery();

  const dispatch = useDispatch();
  const [deactivateEmployee, deactivateEmployeeResponse] =
    useLazyGetApiAdminActivateDeactivateEmployeeQuery();

  const isDeleteModal = useSelector(
    state => state.staffManagment.isDeleteModalOpens,
  );
  const selectedEmploye = useSelector(
    state => state.staffManagment.selectedEmployeeData,
  );
  const isLinkGeneratorPopupOpens = useSelector(
    state => state.staffManagment.isLinkGeneratorPopup,
  );
  const selectedMenuOptions = useSelector(
    state => state.staffManagment.selectedMenuOptions,
  );
  const isCommissionPopUp = useSelector(
    state => state.staffManagment.isCommissionPopUp,
  );
  const isAccountVerificationPopUp = useSelector(
    state => state.staffManagment.isAccountVerificationPopUp,
  );
  const isRequestDocumentsPopup = useSelector(
    state => state.staffManagment.isRequestDocumentsPopup,
  );
  const isDownloadFilesPopup = useSelector(
    state => state.staffManagment.isDownloadFilesPopup,
  );
  const isDownloadSuccessPopup = useSelector(
    state => state.staffManagment.isDownloadSuccessPopup,
  );
  const isEditModal = useSelector(state => state.staffManagment.isEditModal);
  if (isSuccess) {
    employeesList = EmployeesData?.content?.list ?? [];
    totalPages = EmployeesData?.content?.totalPages;
  } else if (isError) {
    dispatch(
      setModalDetails({
        type: ENUMS.POPUP_ENUMS.ERROR,
        details: error,
      }),
    );
    dispatch(openModal());
  }

  useEffect(() => {
    if (isLoggedIn) {
      // let {roleId} = getProfile();
      // setUserRole(roleId);
    }
  }, [isLoggedIn]);
  useEffect(() => {
    getEmployeeListHandler();
  }, [currentPage]);
  const getEmployeeListHandler = () => {
    const payload = {
      employee: {
        company: '',
        email: emailFromURL ?? '',
        role: selectedStaff.role,
        status: selectedStaff.status,
        pageNo: currentPage,
        pageSize: ENUMS.PAGE_SIZE,
      },
    };
    fetchAllEmployees(payload);
  };
  const fetchAllEmployees = async payload => {
    try {
      await getAllEmployees(payload);
    } catch (error) {
      dispatch(
        setModalDetails({
          type: ENUMS.POPUP_ENUMS.ERROR,
          details: error,
        }),
      );
      dispatch(openModal());
    }
  };

  const activeOrDeactiveEmployee = async () => {
    let result = await deactivateEmployee({
      employeeId: selectedEmploye.employeeId,
      status: !selectedEmploye.isActive,
    });

    if (result && result.data?.success) {
      dispatch(setDeleteModal(false));
      toast.success(result.data?.description);
      const payload = {
        employee: {
          company: '',
          email: '',
          role: selectedStaff.role,
          status: selectedStaff.status,
          pageNo: currentPage,
          pageSize: ENUMS.PAGE_SIZE,
        },
      };
      fetchAllEmployees(payload);
    }
  };

  const {
    STAFF: {
      ROLE: {
        ADMIN,
        AGENT,
        AGENCY,
        AGENCY_MANAGER,
        ALL,
        CUSTOMER_SUPPORT,
        SALES_REP,
        // DISTRIBUTION_CHANNEL,
        BUSINESS_AMB,
        INDEPANDANT_AGENT,
      },
      ROLE,
    },
  } = ENUMS;

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const staffSearchhandler = async (
    email,
    roleDropDown,
    statusDropDown,
    clear,
  ) => {
    let payload;
    if (clear) {
      payload = {
        employee: {
          company: '',
          email: '',
          role: 0,
          status: 0,
          pageNo: 1,
          pageSize: ENUMS.PAGE_SIZE,
        },
      };
      setSelectedStaff({
        role: 0,
        status: 0,
      });
    } else {
      let staffRole = getValuesFromEnumsObj(ENUMS.STAFF.ROLE, roleDropDown);
      let staffStatus = getValuesFromEnumsObj(
        ENUMS.STAFF.STATUS,
        statusDropDown,
      );
      // Handling Indepandant Agent and Agency Agents
      const profile = getProfile();
      if (profile.roleId == ROLE.AGENCY.VALUE) {
        if (staffRole === 8) {
          staffRole = 3;
        }
      } else if (
        profile.roleId == ROLE.ADMIN.VALUE
        // ||
        // profile.roleId == ROLE.DISTRIBUTION_CHANNEL.VALUE
      ) {
        if (staffRole === 3) {
          staffRole = 8;
        }
      }
      payload = {
        employee: {
          company: '',
          email: email,
          role: staffRole,
          status: staffStatus,
          pageNo: 1,
          pageSize: ENUMS.PAGE_SIZE,
        },
      };
      setSelectedStaff({
        role: staffRole,
        status: staffStatus,
      });
    }
    setCurrentPage(1);
    setBtnLoader(true);
    await fetchAllEmployees(payload);
    setBtnLoader(false);
  };
  const getRoleDropDownValues = () => {
    const profile = getProfile();
    if (profile.roleId == ROLE.ADMIN.VALUE) {
      return [
        ALL.LABEL,
        ADMIN.LABEL,
        AGENT.LABEL,
        AGENCY.LABEL,
        AGENCY_MANAGER.LABEL,
        CUSTOMER_SUPPORT.LABEL,
        SALES_REP.LABEL,
        // DISTRIBUTION_CHANNEL.LABEL,
        BUSINESS_AMB.LABEL,
        INDEPANDANT_AGENT.LABEL,
      ];
      // } else if (profile.roleId == ROLE.DISTRIBUTION_CHANNEL.VALUE) {
      //   return [
      //     ALL.LABEL,
      //     AGENT.LABEL,
      //     AGENCY.LABEL,
      //     CUSTOMER_SUPPORT.LABEL,
      //     SALES_REP.LABEL,
      //     BUSINESS_AMB.LABEL,
      //   ];
    } else if (profile.roleId == ROLE.AGENCY.VALUE) {
      return [ALL.LABEL, AGENT.LABEL, AGENCY_MANAGER.LABEL];
    } else if (profile.roleId == ROLE.BUSINESS_AMB.VALUE) {
      return [
        BUSINESS_AMB.LABEL,
        INDEPANDANT_AGENT.LABEL,
        SALES_REP.LABEL,
        ALL.LABEL,
      ];
    } else {
      return [CUSTOMER_SUPPORT.LABEL, ALL.LABEL];
    }
  };

  useEffect(() => {
    if (isLinkGeneratorPopupOpens) {
      getModalBody();
    }
  }, [isLinkGeneratorPopupOpens]);

  const getModalBody = async () => {
    setPopuoLink('');
    if (
      selectedMenuOptions ==
      staffLinkpopupTypes[ALL_TEXT.GENERATE_FORGOT_PASSWORD_LINK]
    ) {
      let response = await forgotPasswordAPI({email: selectedEmploye.email});
      if (response && response.data.success) {
        setPopuoLink(response.data.content);
      } else {
        setPopuoLink(response?.data?.description || '');
      }
    } else {
      let response = await activationLinkAPI({
        employeeId: selectedEmploye.employeeId,
      });
      if (response && response.data.success) {
        setPopuoLink(response.data.content);
      } else {
        setPopuoLink(response?.data?.description || '');
      }
    }
  };

  return (
    <div className="claimlist-main-container">
      <div className="title-container-staff">
        <div>
          <span>{ALL_TEXT.ADMINISTRATION} / </span>
          <span>{ALL_TEXT.STAFF_MAINTAINANCE}</span>
        </div>
        <GenericButton
          customStyle={'custom-register-btn'}
          buttonText={`+${ALL_TEXT.USER_DETAIL.ADD_NEW_STAFF_TITLE}`}
          onPress={() => {
            dispatch(setSelectedEmployeeData({}));
            dispatch(setEditModal(false));
            setAddNewUser(true);
          }}
        />
      </div>
      <div className="manageCarrierList-search-bar">
        <CustomizedSearch
          title={ALL_TEXT.SEARCH_STAFF_BY}
          firstLabel={ALL_TEXT.EMAIL}
          firstPlaceholder={ALL_TEXT.ENTER_EMAIL}
          emailfilter={emailFromURL}
          firstDropdown
          firstDropdownLabel={ALL_TEXT.ROLE}
          firstDropdownList={getRoleDropDownValues()}
          secondDropdown
          secondDropdownLabel={ALL_TEXT.STATUS}
          seconddropdownlist={[
            ENUMS.USERS.STATUS.ACTIVE.LABEL,
            ENUMS.USERS.STATUS.INACTIVE.LABEL,
            ENUMS.USERS.STATUS.ALL.LABEL,
          ]}
          onClearFilterHandler={() => {
            staffSearchhandler('', '', '', true);
          }}
          btnLoader={btnLoader}
          onSearchBtnPress={(email, _, __, roleDropDown, statusDropDown) => {
            staffSearchhandler(email, roleDropDown, statusDropDown, false);
          }}
        />
      </div>
      <div className="table-manageCarrierList-container">
        <GenericTable
          headings={TableHeading()}
          data={employeesList}
          loader={isLoading}
        />
      </div>
      {totalPages > 1 && (
        <div className="pagination-container">
          <div className="inner-pagination">
            <Pagination
              page={currentPage}
              color={'secondary'}
              onChange={handlePageChange}
              count={totalPages}
            />
          </div>
        </div>
      )}
      {(addNewUser || isEditModal) && (
        <AddNewUser
          buttonText={isEditModal ? 'Update' : 'Add'}
          addStaffMember
          isEditStaffModal={isEditModal}
          show={addNewUser || isEditModal}
          handleClose={isCheck => {
            if (isCheck === true) {
              getEmployeeListHandler();
            }
            setAddNewUser(false);
            dispatch(setEditModal(false));
          }}
        />
      )}
      {isDeleteModal && (
        <GenericModal
          show={isDeleteModal}
          type={ENUMS.MODAL_TYPES.ALERT}
          title={
            selectedEmploye?.isActive
              ? ALL_TEXT.DISASTER.DEACTIVATE
              : ALL_TEXT.DISASTER.ACTIVATE
          }
          buttonText={ALL_TEXT.YES}
          buttonText2={ALL_TEXT.NO}
          button2
          btnLoader={deactivateEmployeeResponse?.isFetching}
          body={
            selectedEmploye?.isActive
              ? ALL_TEXT.DEACTIVATE_ACCOUNT
              : ALL_TEXT.ACTIVATE_ACCOUNT
          }
          primaryBtnPress={() => {
            activeOrDeactiveEmployee();
          }}
          secondaryBtnPress={() => {
            dispatch(setDeleteModal(false));
          }}
          grayButton2
        />
      )}
      {isLinkGeneratorPopupOpens && (
        <GenericModal
          show={isLinkGeneratorPopupOpens}
          btnLoader={
            forgotPasswordResponse?.isFetching ||
            activationLinkResponse.isFetching
          }
          type={ENUMS.POPUP_ENUMS.ALERT}
          title={
            selectedMenuOptions ==
            staffLinkpopupTypes[ALL_TEXT.GENERATE_FORGOT_PASSWORD_LINK]
              ? ALL_TEXT.FORGOT_PASSWORD_LINK
              : ALL_TEXT.ACCOUNT_SETUP_LINK
          }
          buttonText={ALL_TEXT.BUTTON_TEXT.OK}
          buttonText2={ALL_TEXT.BUTTON_TEXT.NO}
          body={<CellValue cell={popuoLink} row={popuoLink} />}
          primaryBtnPress={() => {
            dispatch(setIsLinkGeneratorPopup(false));
          }}
        />
      )}
      {isCommissionPopUp && (
        <AddEdiCommission
          show={isCommissionPopUp}
          handleClose={() => {
            dispatch(setIsCommissionPopUp(false));
          }}
          data={selectedEmploye}
        />
      )}
      {isAccountVerificationPopUp && (
        <AccountVerificationstatus
          show={isAccountVerificationPopUp}
          handleClose={() => {
            dispatch(setIsAccountVerificationPopUp(false));
          }}
          getEmployees={() => {
            getEmployeeListHandler();
          }}
          data={selectedEmploye}
        />
      )}
      {isRequestDocumentsPopup && (
        <RequestDocuments
          show={isRequestDocumentsPopup}
          handleClose={() => {
            dispatch(setIsRequestDocumentPopup(false));
          }}
          data={selectedEmploye}
        />
      )}
      {isDownloadFilesPopup && (
        <DownloadFilesPopup
          show={isDownloadFilesPopup}
          loading={isDownloadFilesPopup}
          data={selectedEmploye}
        />
      )}
      {isDownloadSuccessPopup && (
        <GenericModal
          show={isDownloadSuccessPopup}
          type={1}
          body={ALL_TEXT.DOWNLOAD_SUCCESSFULLY}
          buttonText={ALL_TEXT.BUTTON_TEXT.OK}
          handleClose={() => {
            if (isDownloadSuccessPopup) {
              dispatch(setIsDownloadSuccessPopup(false));
            }
          }}
        />
      )}
    </div>
  );
}

export default StaffManagment;
