import React, {useEffect, useState} from 'react';
import './style.scss';
import {ALL_TEXT, ENUMS, QUERYPARAM, colors} from '../../../common';
import CustomerProfileLayout from '../main-layout';
import {useLocation} from 'react-router';
import {
  ConfirmCancellationPopup,
  CustomizedSearch,
  InvoiceAndSubscriptionDetails,
  Loader,
  PolicyCard,
} from '../../../components';
import Pagination from '@mui/material/Pagination';
import {getValuesFromEnumsObj, planCategory} from '../../../utils/helpers';
import {
  cancelCustomerSubscription,
  getCustomerSubscription,
} from '../../../utils/rest-services';
import {useDispatch, useSelector} from 'react-redux';
import {openModal, setModalDetails} from '../../../redux/slice/popup-modal';

const CustomerSubscription = () => {
  const {
    SUBSCRIPTION: {ACTIVE, CANCELLED},
    SUBSCRIPTION_TYPES: {MONTHLY, BIANNUAL, ANNUAL, ALL},
  } = ENUMS;

  const [btnloader, setBtnloader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [detailsModal, setDetailsModal] = useState(false);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [subscriptionStatus, setSubscriptionStatus] = useState(0);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(0);
  const [cancelReason, setCancelReason] = useState('');
  const [cancelablePolicy, setCancelablePolicy] = useState({
    isOpen: false,
    reason: '',
    cartItemId: 0,
    signupId: 0,
  });
  const dispatch = useDispatch();

  const location = useLocation();
  const getParamFromUrl = () => {
    return new URLSearchParams(location.search);
  };
  const isSelectedUser = useSelector(
    state => state.checkout.isUserChange ?? null,
  );
  useEffect(() => {
    const payload = {
      signupId: signupId,
      pageNo: currentPage,
      rowsPerPage: ENUMS.PAGE_SIZE,
      status: subscriptionStatus,
      id: 0,
      subscriptionType: '0',
    };
    getCustomerSubscriptionHandler(payload);
  }, [currentPage, isSelectedUser]);

  const getCustomerSubscriptionHandler = async payload => {
    setLoader(true);
    let response = await getCustomerSubscription(payload);
    if (response && response.success) {
      const {
        content: {list, totalPages},
      } = response;
      setSubscriptionList(list || []);
      setTotalPage(totalPages);
    }
    setLoader(false);
  };

  let query = getParamFromUrl();
  const signupId = query.get(QUERYPARAM.SIGNUPID);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const subscriptionSearchHandler = async (
    subscriptionId,
    type,
    status,
    clear,
  ) => {
    let payload;
    if (clear) {
      payload = {
        signupId: signupId,
        pageNo: 1,
        rowsPerPage: ENUMS.PAGE_SIZE,
        status: 0,
        id: 0,
        subscriptionType: '0',
      };
    } else {
      const subscriptionStatus = getValuesFromEnumsObj(
        ENUMS.SUBSCRIPTION,
        status,
      );
      setSubscriptionStatus(subscriptionStatus);
      payload = {
        signupId: signupId,
        pageNo: 1,
        rowsPerPage: ENUMS.PAGE_SIZE,
        status: subscriptionStatus,
        id: subscriptionId
          ? subscriptionId?.split('_')[1] || subscriptionId
          : 0,
        subscriptionType:
          type?.toLowerCase() === 'all'
            ? '0'
            : type?.toLowerCase() === 'monthly'
            ? 'month'
            : type?.toLowerCase() || '0',
      };
    }
    setCurrentPage(1);
    setBtnloader(true);
    await getCustomerSubscriptionHandler(payload);
    setBtnloader(false);
  };

  const confirmCancelSubscriptionHandler = async () => {
    setBtnloader(true);
    const payload = {
      signupId: cancelablePolicy.signupId,
      cancelReason: cancelReason,
      subscriptionId: cancelablePolicy.subscriptionId,
      stripeSubscriptionId: cancelablePolicy.stripeSubscriptionId,
    };
    let response = await cancelCustomerSubscription(payload);
    setBtnloader(false);
    if (response && response.data.success) {
      setCancelReason('');
      setCancelablePolicy({isOpen: false});
      const payload = {
        signupId: signupId,
        pageNo: currentPage,
        rowsPerPage: ENUMS.PAGE_SIZE,
        status: subscriptionStatus,
        id: 0,
        subscriptionType: '0',
      };
      getCustomerSubscriptionHandler(payload);
    }

    // let result = await cancelSubscription(payload);
  };
  const getSubscriptionStatus = status => {
    if (!status) {
      return (
        <span class="badge rounded-pill bg-danger text-light">
          {CANCELLED.LABEL}
        </span>
      );
    } else {
      return <span class="badge rounded-pill bg-success">{ACTIVE.LABEL}</span>;
    }
  };
  return (
    <CustomerProfileLayout selectedId={ENUMS.CUSTOMER_TAB_INDEX.SUBSCRIPTION}>
      <div className="customer-subscription">
        <CustomizedSearch
          title={'Search Subscription By'}
          firstLabel="Subscription Id"
          isFirstInputNumeric
          firstPrefixerValue={'sub_'}
          firstPlaceholder={'Enter Subscription Id'}
          firstDropdown
          firstDropdownLabel={'Subscription Type'}
          firstDropdownList={[
            MONTHLY.LABEL,
            BIANNUAL.LABEL,
            ANNUAL.LABEL,
            ALL.LABEL,
          ]}
          secondDropdown
          secondDropdownLabel={'Status'}
          seconddropdownlist={[ACTIVE.LABEL, CANCELLED.LABEL, ALL.LABEL]}
          onClearFilterHandler={() => {
            subscriptionSearchHandler('', '', '', true);
          }}
          onSearchBtnPress={(subscriptionId, _, __, type, status) => {
            subscriptionSearchHandler(subscriptionId, type, status);
          }}
          btnLoader={btnloader}
        />
        {!loader && subscriptionList.length === 0 && (
          <div className="loader-container-dasboard flex-center">
            <span> {ALL_TEXT.NO_SUBSCRIPTION}</span>
          </div>
        )}
        {loader ? (
          <div className="loader-container-dasboard">
            <Loader />
          </div>
        ) : (
          subscriptionList.length > 0 &&
          subscriptionList?.map((item, index) => {
            const {
              isCancelable,
              cardType,
              isActive,
              subscriptionTotal,
              viewSubscriptionId,
              subscriptionType,
              createdDate,
              cardNumber,
              subscriptionId,
              stripeSubscriptionId,
            } = item || {};
            return (
              <PolicyCard
                isActive={isActive}
                key={index}
                cardType={ENUMS.VIEW_CARD_TYPE.SUBSCRIPTION}
                onDetailBtnPress={() => {
                  setDetailsModal(true);
                  setSelectedSubscriptionId(viewSubscriptionId?.split('_')[1]);
                }}
                onDeleteBtnPress={() => {
                  if (isCancelable) {
                    // cancel policy popup
                    setCancelablePolicy({
                      isOpen: true,
                      signupId: signupId,
                      reason: cancelReason,
                      subscriptionId,
                      stripeSubscriptionId,
                    });
                  } else {
                    dispatch(
                      setModalDetails({
                        title: ALL_TEXT.CANT_CANCEL_SUBS,
                        details: ALL_TEXT.CANT_CANCEL_SUBS_DETAILS,
                        type: ENUMS.MODAL_TYPES.ALERT,
                      }),
                    );
                    dispatch(openModal());
                  }
                }}
                planIconTitle={
                  <div className="plan-name">
                    <span>{`${viewSubscriptionId}`}</span>
                  </div>
                }
                badge={getSubscriptionStatus(isActive)}
                cost={
                  <span class={planCategory(subscriptionType)}>
                    {subscriptionType}
                  </span>
                }
                LeftTopDetails={
                  <div className="details-item">
                    <span>{ALL_TEXT.TOTAL_AMOUNT}</span>
                    <span className="">{`$${parseFloat(
                      subscriptionTotal,
                    ).toFixed(2)}`}</span>
                  </div>
                }
                LeftBottomDetails={
                  <div className="details-item">
                    <span>{ALL_TEXT.CREATED_DATE}</span>
                    <span>{`${createdDate.split('T')[0]}`}</span>
                  </div>
                }
                rightTopDetails={
                  <div className="details-item">
                    <span>{ALL_TEXT.BILLING_CARD}</span>
                    <div className="billing-card-digits">
                      <i
                        className={`subscription-card-type fa-brands fa-cc-${cardType}`}
                        style={{color: colors.primary}}></i>

                      <span>
                        {cardNumber ? `**** **** **** ${cardNumber}` : 'N/A'}
                      </span>
                    </div>
                  </div>
                }
                rigthBottomDetails={
                  <div className="details-item extra-card "></div>
                }
              />
            );
          })
        )}
      </div>
      {totalPage > 1 && (
        <div className="pagination-container">
          <div className="inner-pagination">
            <Pagination
              page={currentPage}
              color={'secondary'}
              onChange={handlePageChange}
              count={totalPage}
            />
          </div>
        </div>
      )}
      {detailsModal && (
        <InvoiceAndSubscriptionDetails
          show={detailsModal}
          subscriptionId={selectedSubscriptionId}
          signUpId={signupId}
          handelClose={() => {
            setDetailsModal(false);
          }}
        />
      )}
      {cancelablePolicy.isOpen && (
        <ConfirmCancellationPopup
          subscription
          show={cancelablePolicy.isOpen}
          reason={cancelReason}
          setReason={setCancelReason}
          loader={btnloader}
          handelSubmit={data => {
            confirmCancelSubscriptionHandler(data);
          }}
          handleClose={() => {
            setBtnloader(false);
            setCancelablePolicy({
              isOpen: false,
            });
          }}
        />
      )}
    </CustomerProfileLayout>
  );
};

export default CustomerSubscription;
