import React, {useEffect, useState} from 'react';
import {colors, ENUMS, QUERYPARAM, ALL_TEXT} from '../../../common';
import CustomerProfileLayout from '../main-layout';
import './style.scss';
import {Grid} from '@mui/material';
import {getCustomerProfile} from '../../../utils/rest-services';
import {FaUserEdit} from 'react-icons/fa';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router';
import {AddNewUser, Loader} from '../../../components';
import {path} from '../../../common/routesNames';
import {setIsUserChange} from '../../../redux/slice/checkout';
import {getProfile} from '../../../utils/localstorage';

const CustomerProfile = () => {
  const [customerData, setCustomerData] = useState({});
  const [editPopup, setEditPopup] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSelectedUser = useSelector(
    state => state.checkout.isUserChange ?? null,
  );
  const signupId = useSelector(
    state => state.checkout.selectedUser?.signupId ?? null,
  );
  const InfoItem = ({keyy, value}) => {
    return (
      <div className="customer-info-item">
        <span>{keyy}</span>
        <span>{value}</span>
      </div>
    );
  };
  const CoverageItem = ({type, text, value}) => {
    return (
      <div className="status-box">
        <div className="icon-with-text">
          <GetServiceTypeIcon type={type} />
          <span className="red-text-title">{text}</span>
        </div>
        <span>{value}</span>
      </div>
    );
  };

  const GetServiceTypeIcon = ({type}) => {
    switch (type) {
      case ENUMS.COVERAGE_TYPES.VEHICLE:
        return <span class="icon-vehuicle"></span>;
      case ENUMS.COVERAGE_TYPES.DEVICE:
        return <span class="icon-devices"></span>;
      case ENUMS.COVERAGE_TYPES.HOME:
        return <span class="icon-home"></span>;
      case ENUMS.COVERAGE_TYPES.COMMERCIALS:
        return <span class="icon-commercial"></span>;

      default:
        null;
    }
  };
  let location = useLocation();
  const getParamFromUrl = () => {
    return new URLSearchParams(location.search);
  };

  let query = getParamFromUrl();
  useEffect(() => {
    getCustomerProfileHandler(signupId);
  }, [signupId]);

  const isActive = query.get('isActive');

  const getCustomerProfileHandler = async signupId => {
    const id = query.get(QUERYPARAM.SIGNUPID);

    setLoader(true);
    let result = await getCustomerProfile(id ?? signupId);
    if (result && result.success) {
      setCustomerData(result.content);
      setLoader(false);
    } else {
      setCustomerData({});
    }
  };
  const userAddedBy = () => {
    if (customerData?.profile?.agentRoleId === 1) {
      return ENUMS.STAFF.ROLE.ADMIN.LABEL;
    } else if (customerData?.profile?.agentRoleId === 3)
      return ENUMS.STAFF.ROLE.AGENT.LABEL;
    else if (customerData?.profile?.agentRoleId === 4)
      return ENUMS.STAFF.ROLE.SALES_REP.LABEL;
    else if (customerData?.profile?.agentRoleId === 5)
      return ENUMS.STAFF.ROLE.AGENCY.LABEL;
    else if (customerData?.profile?.agentRoleId === 6)
      return ENUMS.STAFF.ROLE.CUSTOMER_SUPPORT.LABEL;
    else if (customerData?.profile?.agentRoleId === 7)
      return ENUMS.STAFF.ROLE.BUSINESS_AMB.LABEL;
    else if (customerData?.profile?.agentRoleId === 8)
      return ENUMS.STAFF.ROLE.INDEPANDANT_AGENT.LABEL;
    else if (customerData?.profile?.agentRoleId === 9)
      return ENUMS.STAFF.ROLE.AGENCY_MANAGER.LABEL;
  };

  return (
    <CustomerProfileLayout selectedId={ENUMS.CUSTOMER_TAB_INDEX.PROFILE}>
      {loader ? (
        <div className="loader-container-dasboard">
          <Loader />
        </div>
      ) : (
        <div className="profile-layout">
          <div className="name-container">
            <span>{`${customerData?.profile?.firstName} ${customerData?.profile?.lastName}`}</span>
            <div className="user-signup-info">
              {customerData?.profile?.agentName && (
                <div className="addby">
                  <span>{ALL_TEXT.ADDED_BY} </span>
                  {customerData?.profile?.agentName}({userAddedBy()})
                </div>
              )}
              <div className="badge-button gray-btn">
                {`Member since ${
                  customerData?.profile?.signupDate?.split(' ')[0]
                }`}
              </div>
            </div>
            {getProfile().roleId !== 6 && (
              <div className="user-profile-last-button">
                {isActive === 'true' && (
                  <>
                    <div
                      onClick={() => {
                        navigate({
                          pathname: path.addPolicy,
                          search: `?${QUERYPARAM.SIGNUPID}=${signupId}}`,
                        });
                      }}
                      className="badge-button red-btn">
                      {`+ ${ALL_TEXT.ADD_NEW_POLICY}`}
                    </div>

                    <div
                      onClick={() => {
                        setEditPopup(true);
                      }}
                      className="badge-button red-btn">
                      <FaUserEdit color={colors.white} />
                      <span>{ALL_TEXT.EDIT}</span>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <Grid className="customer-info-secondary" container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <InfoItem
                keyy={ALL_TEXT.USER_DETAIL.PHONE.slice(0, -1)}
                value={`${customerData?.profile?.phone}`}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <InfoItem
                keyy={ALL_TEXT.USER_DETAIL.EMAIL.slice(0, -1)}
                value={`${customerData?.profile?.email}`}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <InfoItem
                keyy={ALL_TEXT.USER_DETAIL.ADDRESS.slice(0, -1)}
                value={customerData?.profile?.address}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <div>
                <span className="coverage-title">
                  {ALL_TEXT.PREVIOUSE_COVERAGE}
                </span>
                <div className="claims-status mt-1">
                  {customerData?.coverages?.coverages?.map((i, index) => (
                    <CoverageItem
                      key={index}
                      type={i?.serviceTypeId}
                      text={i?.descriptionText}
                      value={i?.totalCost}
                    />
                  ))}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <div>
                <span className="coverage-title">{ALL_TEXT.CLOSED_CLAIMS}</span>
                <div className="claims-status mt-1">
                  {customerData?.coverages?.coverages?.map((i, index) => (
                    <CoverageItem
                      key={index}
                      type={i?.serviceTypeId}
                      text={`${i?.descriptionText} ${ALL_TEXT.CLAIMS}`}
                      value={i?.closedClaims}
                    />
                  ))}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}

      {editPopup && (
        <AddNewUser
          signupId={signupId}
          show={editPopup}
          editCustomer
          isEditStaffModal={true}
          customerData={customerData}
          buttonText={ALL_TEXT.UPDATE}
          handleClose={isCheck => {
            if (isCheck) {
              getCustomerProfileHandler();
              dispatch(setIsUserChange(!isSelectedUser));
            }
            setEditPopup(false);
          }}
        />
      )}
    </CustomerProfileLayout>
  );
};

export default CustomerProfile;
