import React, {useEffect} from 'react';
import UploadAndDownload from '../../components/download-upload-bar';
import {useState} from 'react';
import './styles.scss';
import {ALL_TEXT, QUERYPARAM, images} from '../../common';
import {GenericButton} from '../../components';
import {RiUpload2Line} from 'react-icons/ri';
import {
  usePostApiAdminUploadDocumentsMutation,
  usePostApiAdminVerifyUploadLinkMutation,
} from '../../redux/slice/nodukAdmin.ts';
import {generateFormData} from '../../utils/helpers';
import {GenericModal} from '../../components/generic-modal';
import {BsArrowDownShort} from 'react-icons/bs';

function UploadDocuments() {
  const [selectedFilesW9F, setSelectedFilesW9F] = useState([]);
  const [selectedFilesEFT, setSelectedFilesEFT] = useState([]);
  const [selectedFilesDriving, setSelectedFilesDriving] = useState([]);
  const [selectedFilesOther, setSelectedFilesOther] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loader, setloader] = useState(false);
  const [popUpResponse, setPopUpResponse] = useState({
    type: '',
    title: '',
    description: '',
  });
  const removeFileW9F = filetoremove => {
    const updatedArray = selectedFilesW9F.filter(
      item => item.name !== filetoremove,
    );
    setSelectedFilesW9F(updatedArray);
  };
  const onChangeImageW9F = e => {
    setSelectedFilesW9F([...selectedFilesW9F, ...e.target.files]);
  };
  const removeFileEFT = filetoremove => {
    const updatedArray = selectedFilesEFT.filter(
      item => item.name !== filetoremove,
    );
    setSelectedFilesEFT(updatedArray);
  };
  const onChangeImageEFT = e => {
    setSelectedFilesEFT([...selectedFilesEFT, ...e.target.files]);
  };
  const removeFileDriving = filetoremove => {
    const updatedArray = selectedFilesDriving.filter(
      item => item.name !== filetoremove,
    );
    setSelectedFilesDriving(updatedArray);
  };
  const onChangeImageDriving = e => {
    setSelectedFilesDriving([...selectedFilesDriving, ...e.target.files]);
  };
  const removeFileOther = filetoremove => {
    const updatedArray = selectedFilesOther.filter(
      item => item.name !== filetoremove,
    );
    setSelectedFilesOther(updatedArray);
  };
  const onChangeImageOther = e => {
    setSelectedFilesOther([...selectedFilesOther, ...e.target.files]);
  };
  useEffect(() => {
    const getParamFromUrl = () => {
      return new URLSearchParams(location.search);
    };
    let query = getParamFromUrl();
    let encyptedId = query.get(QUERYPARAM.ENC_ID);
    verifyUploadLinkApi({emp: encyptedId}).then(res => {
      let data;
      if (res.data) {
        data = res.data;
      } else {
        data = res.error.data;
      }
      if (data && !data.success) {
        setPopUpResponse({
          type: 2,
          description: ALL_TEXT.INAVLID_LINK,
        });
        setShowModal(true);
      }
    });
  }, []);
  const [uploadDcumentsApi] = usePostApiAdminUploadDocumentsMutation();

  const handleLSubmit = async () => {
    setloader(true);
    const getParamFromUrl = () => {
      return new URLSearchParams(location.search);
    };
    let query = getParamFromUrl();
    let enId = query.get(QUERYPARAM.ENC_ID);
    const valuePayload = {
      FirstName: '',
      LastName: '',
      EmployeeId: enId,
    };
    const docPayload = {
      W9Form: selectedFilesW9F,
      EFT: selectedFilesEFT,
      License: selectedFilesDriving,
      Others: selectedFilesOther,
    };
    let formdataPayload = generateFormData(valuePayload, docPayload);
    await uploadDcumentsApi({body: formdataPayload}).then(res => {
      if (res.data.success) {
        setloader(false);
        setShowModal(true);
        setPopUpResponse({
          type: 1,
          description: ALL_TEXT.DOCUMENTS_UPLOADED,
        });
      }
    });
  };
  const formIsValid =
    selectedFilesW9F.length > 0 &&
    selectedFilesEFT.length > 0 &&
    selectedFilesDriving.length > 0;
  const [verifyUploadLinkApi] = usePostApiAdminVerifyUploadLinkMutation();
  const handleModalButtonClick = () => {
    window.location.href = '/login';
  };

  return (
    <>
      <div className="noduk-logo">
        <img src={images.newNodukLogo} alt={ALL_TEXT.ICON} />
      </div>
      <div className="DownloadFormtext">
        <span>Download Form</span>
      </div>
      <div className="maindiv">
        <UploadAndDownload
          name={ALL_TEXT.W9_Form}
          icon={<BsArrowDownShort />}
          text={ALL_TEXT.DOWNLOAD}
          uploaditem={false}
          className={'downloadcustombtn'}
          W9Form={1}
        />
        <UploadAndDownload
          name={ALL_TEXT.EFT_Banking}
          icon={<BsArrowDownShort />}
          text={ALL_TEXT.DOWNLOAD}
          uploaditem={false}
          className={'downloadcustombtn'}
          W9Form={2}
        />
      </div>
      <div className="UploadFormtext">
        <span>Upload Documents</span>
      </div>
      <div className="uploaddocumentscontainer">
        <div className="uploadmaindiv">
          <div className="Uploadouterdiv">
            <UploadAndDownload
              name={ALL_TEXT.W9_Form}
              icon={<RiUpload2Line />}
              text={ALL_TEXT.UPLOAD}
              uploaditem={true}
              filesData={selectedFilesW9F}
              className={'uploadloadcustombtn'}
              onChangeImage={onChangeImageW9F}
              removeFile={removeFileW9F}
              W9Form={3}
            />
          </div>
          <div className="Uploadouterdiv">
            <UploadAndDownload
              name={ALL_TEXT.EFT_Banking}
              icon={<RiUpload2Line />}
              text={ALL_TEXT.UPLOAD}
              valueone={true}
              valuetwo={true}
              uploaditem={true}
              filesData={selectedFilesEFT}
              className={'uploadloadcustombtn'}
              onChangeImage={onChangeImageEFT}
              removeFile={removeFileEFT}
              W9Form={3}
            />
          </div>
        </div>
        <div className="uploadseconddiv">
          <div className="Uploadouterdiv">
            <UploadAndDownload
              name={ALL_TEXT.DRIVER_LICENSE}
              icon={<RiUpload2Line />}
              text={ALL_TEXT.UPLOAD}
              valueone={true}
              uploaditem={true}
              filesData={selectedFilesDriving}
              className={'uploadloadcustombtn'}
              onChangeImage={onChangeImageDriving}
              removeFile={removeFileDriving}
              W9Form={3}
            />
          </div>
          <div className="Uploadouterdiv">
            <UploadAndDownload
              name={ALL_TEXT.OTHER}
              icon={<RiUpload2Line />}
              text={ALL_TEXT.UPLOAD}
              valueone={true}
              uploaditem={true}
              filesData={selectedFilesOther}
              className={'uploadloadcustombtn'}
              onChangeImage={onChangeImageOther}
              removeFile={removeFileOther}
              W9Form={3}
            />
          </div>
        </div>
        <div className="submitbtn">
          <GenericButton
            buttonText={'Submit'}
            onPress={handleLSubmit}
            disable={!formIsValid}
            loading={loader}
          />
        </div>
      </div>

      <GenericModal
        show={showModal}
        type={'1'}
        body={popUpResponse.description}
        buttonText={ALL_TEXT.BUTTON_TEXT.OK}
        handleClose={() => {
          if (showModal) {
            setShowModal(false);
            handleModalButtonClick();
          }
        }}
      />
    </>
  );
}
export default UploadDocuments;
