import React, {useState} from 'react';
import moment from 'moment/moment';
import {Formik} from 'formik';
import {Modal} from 'react-bootstrap';
import './styles.scss';
import {ALL_TEXT, ENUMS} from './../../common/constants';
import {InputField} from '../input-field';
import {GenericButton} from '../generic-button';
import {CriteriaDropdown} from '../criteria-dropdown';
import {updateClaimScehma} from '../../common/schemas';
import {TextAreaFeild} from '../textarea';
import {ChooseFile} from '../choose-file';
import {updateClaimInfo} from '../../utils/rest-services';
import {GenericModal} from '../generic-modal';
import {generateFormData, getValuesFromEnumsObj} from '../../utils/helpers';

export const ModalUpdateClaim = ({
  show,
  handleClose,
  status,
  id,
  claimFileDate,
  dateField,
}) => {
  const [btnLoader, setBtnLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [adminDocs, setAdminDocs] = useState([]);
  const [responseModal, setResponseModal] = useState({
    type: ENUMS.POPUP_ENUMS.SUCCESS,
    title: '',
    details: '',
  });

  const options = [
    {
      value: ENUMS.CLAIMS.STAND.SETTLED.VALUE,
      label: ENUMS.CLAIMS.STAND.SETTLED.LABEL,
    },
    {
      value: ENUMS.CLAIMS.STAND.CANCELLED.VALUE,
      label: ENUMS.CLAIMS.STAND.CANCELLED.LABEL,
    },
    {
      value: ENUMS.CLAIMS.STAND.REJECTED.VALUE,
      label: ENUMS.CLAIMS.STAND.REJECTED.LABEL,
    },
  ];
  const dropDownOptions = [
    {
      value: ENUMS.CLAIMS.STAND.PENDING.VALUE,
      label: ENUMS.CLAIMS.STAND.PENDING.LABEL,
    },
    {
      value: ENUMS.CLAIMS.STAND.SETTLED.VALUE,
      label: ENUMS.CLAIMS.STAND.SETTLED.LABEL,
    },
    {
      value: ENUMS.CLAIMS.STAND.CANCELLED.VALUE,
      label: ENUMS.CLAIMS.STAND.CANCELLED.LABEL,
    },
    {
      value: ENUMS.CLAIMS.STAND.REJECTED.VALUE,
      label: ENUMS.CLAIMS.STAND.REJECTED.LABEL,
    },
  ];

  const updateClaimInformation = async (value, formik) => {
    const statusId = getValuesFromEnumsObj(
      ENUMS.CLAIMS.STAND,
      value.status.label || status,
    );
    const claimInfo = {
      DateSettled: value.date,
      Status: statusId || 0,
      Notes: value?.notes || '',
      ClaimId: id || 0,
    };
    const adminDocsObj = {
      UpdateDocs: adminDocs || null,
    };
    const claimDetail = generateFormData(claimInfo, adminDocsObj);
    setBtnLoader(true);
    let response = await updateClaimInfo(claimDetail);
    setBtnLoader(false);
    formik.setSubmitting(false);
    if (response && response.data.success) {
      setShowModal(true);
      setResponseModal({
        type: ENUMS.POPUP_ENUMS.SUCCESS,
        title: ALL_TEXT.GENERIC_MODAL.SUCCESS,
        details: response.data.description,
      });
    } else {
      setShowModal(true);
      setResponseModal({
        type: ENUMS.POPUP_ENUMS.ERROR,
        title: ALL_TEXT.GENERIC_MODAL.ERROR,
        details: response.data.description,
      });
    }
  };
  const onFileRemove = file => {
    let tempState = [...adminDocs];
    let index = tempState.findIndex(e => e == file);
    index != -1 && tempState.splice(index, 1);
    setAdminDocs(tempState);
  };
  const onFileAdded = file => {
    const tempState = [...adminDocs];
    tempState.push(file);
    setAdminDocs(tempState);
  };

  const findValueInArray = () => {
    const index = dropDownOptions.findIndex(
      item => item.label.toLowerCase() === status.toLowerCase(),
    );
    if (index != -1) {
      return dropDownOptions[index];
    } else {
      return '';
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          date: claimFileDate
            ? moment(claimFileDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
            : new Date().toISOString().split('T')[0],
          status: findValueInArray(),
          values: '',
        }}
        validateOnMount={true}
        validationSchema={updateClaimScehma}
        onSubmit={(values, formik) => {
          updateClaimInformation(values, formik);
        }}
        enableReinitialize
        context={{dateField}}>
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
          values,
        }) => (
          <div className="update-claim-modal">
            <Modal show={show} onHide={handleClose} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>{ALL_TEXT.UPDATE_CLAIM}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="update-claim-modal-body">
                <div className="claim-modal-content">
                  <label htmlFor="/">{ALL_TEXT.DATE_UPDATED}</label>
                  {/* <InputField
                    placeholder={ALL_TEXT.DATE}
                    type={'date'}
                    id={'date'}
                    name={'date'}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={touched.date && errors.date}
                    value={values.date}
                    min={moment(dateField, 'M/DD/YYYY').format('YYYY-MM-DD')}
                    max={moment('M/DD/YYYY').format('YYYY-MM-DD')}
                    onKeyPress={(e) => e.preventDefault()}
                  /> */}
                  <InputField
                    placeholder={ALL_TEXT.DATE}
                    type={'date'}
                    id={'date'}
                    name={'date'}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={touched.date && errors.date}
                    value={values.date}
                    min={
                      values.date
                        ? moment(values.date, 'M/DD/YYYY').format('YYYY-MM-DD')
                        : ''
                    }
                    max={moment().format('YYYY-MM-DD')}
                    onKeyPress={e => e.preventDefault()}
                  />
                  <label htmlFor="/">{ALL_TEXT.STATUS_}</label>
                  <CriteriaDropdown
                    id="status"
                    name="status"
                    options={options}
                    selectedOption={values.status}
                    dropdowntitle={[ALL_TEXT.SELECT]}
                    error={touched.status && errors.status}
                    onChangeOption={e => {
                      setFieldValue('status', e);
                    }}
                  />
                  <div className="texarea-box">
                    <label htmlFor="/">{ALL_TEXT.NOTES}</label>
                    <TextAreaFeild
                      id={'notes'}
                      name={'notes'}
                      value={values.notes}
                      setValue={handleChange}
                    />
                  </div>
                  <div className="choose-file-container ">
                    <ChooseFile
                      files={adminDocs}
                      onRemoveFileHandler={item => {
                        onFileRemove(item);
                      }}
                      onChangeHandler={file => {
                        onFileAdded(file);
                      }}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <GenericButton
                  loading={btnLoader}
                  buttonText={ALL_TEXT.UPDATE}
                  onPress={
                    handleSubmit
                    // handleClose
                  }
                />
              </Modal.Footer>
            </Modal>
          </div>
        )}
      </Formik>
      <GenericModal
        show={showModal}
        type={responseModal.type}
        title={responseModal.title}
        body={responseModal.details}
        buttonText={ALL_TEXT.OK}
        handleClose={() => {
          if (responseModal.type === ENUMS.POPUP_ENUMS.SUCCESS) {
            setShowModal(false);
            handleClose(true);
          } else {
            setShowModal(false);
          }
        }}
      />
    </>
  );
};
